<template v-if="formActive">
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="customer-tab" data-bs-toggle="tab" data-bs-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true" @click="setTab(1)">
                {{'1 - ' + $t('sales.carrier_validity')}}
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="item-tab" data-bs-toggle="tab" data-bs-target="#items" type="button" role="tab" aria-controls="profile" @click="setTab(2)">
                {{'2 - ' + $tc('sales.surcharge_items', 2)}}
            </button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">

    <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">

        <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.carrier_validity')}}</h5>

        <div class="row">
            <FormItem id="carrier_id" :label="$tc('navigation.carriers', 2)" type="livesearch-list" v-model="surcharge.carriers" :options="carriers" :content="surcharge.carriers.name" 
                      sizeLabel="2" sizeItem="4" @add="checkStepOne" @delete="checkStepOne" :disabled="isDisabled" />
        </div> 

        <div class="row mt-3">
            <label class="col-sm-2 col-form-label col-form-label-sm">{{$t('sales.valid_from_to')}}</label>
            <div class="col-sm-2 has-validation start-date">
                <FormItem id="startdate" type="datepicker" v-model="surcharge.start_date" @onchange="validateDate" :extraOption="startDate" :disabled="isDisabled" />
            </div>
            <div class="col-sm-2 has-validation end-date">
                <FormItem id="enddate" type="datepicker" v-model="surcharge.end_date" @onchange="validateDate" :extraOption="endDate" :disabled="isDisabled" />
                <div class="invalid-feedback"> <i class="fa fa-warning"></i>&nbsp;<span id="edatespan"> {{$t('errors.date_in_past')}} </span></div>
            </div>
        </div>

    </div>

    <div class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="item-tab">

        <h5 class="mt-4 mb-3 fw-bold">{{$tc('sales.surcharge_items', 2)}}</h5>

        <div class="accordion" id="contract-accordion">
            <div v-for="(surchargeitem,index) in surcharge.items" :key="index" class="accordion-item">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button class="acc-header-item header-main accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+index" aria-expanded="true" 
                           :aria-controls="'collapse-'+index" @click.prevent="collapseOthers(index)">
                        <div v-if="isOneOfEachFieldFilledIn(surchargeitem)" class="d-flex w-100">
                            <div class="col-3">{{getLoadingPortsString(surchargeitem, 'loading_ports')}}</div>
                            <i class="col-1 fa fa-arrow-right mt-1"></i>
                            <div class="col-3">{{getLoadingPortsString(surchargeitem, 'discharge_ports')}}</div>
                            <div class="col-5">{{getCommodityNames(index)}}</div>
                        </div>
                        <span v-else>{{$t('sales.new_x', [$tc('sales.surcharge_items', 1)])}}</span>
                    </button>
                    <button type="button" class="acc-header-item header-remove-item" @click="copySurchargeItem(surchargeitem, index)" :disabled="isDisabled"><i class="fal fa-copy"></i></button>
                    <button type="button" class="acc-header-item header-remove-item" @click="deleteSurchargeItem(index)" :disabled="isDisabled"><i class="fal fa-trash-alt"></i></button>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div class="accordion-collapse collapse show" :id="'collapse-'+index" :aria-labelledby="'heading-'+index" data-bs-parent="contract-accordion">
                    <div class="accordion-body">
                        <div class="row mb-1">
                            <label class="col-6 required-label">{{$tc('sales.loading_ports', 2)}}</label>
                            <label class="col-6 required-label">{{$tc('sales.discharge_ports', 2)}}</label>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <FormItem :id="'loadingport-'+index" type="livesearch-list" v-model="surchargeitem.loading_ports" :options="ports" :content="surchargeitem.loading_ports.name" :disabled="isDisabled" />
                            </div>
                            <div class="col-6">
                                <FormItem :id="'dischargeport-'+index" type="livesearch-list" v-model="surchargeitem.discharge_ports" :options="ports" :content="surchargeitem.discharge_ports.name" :disabled="isDisabled" />
                            </div>
                        </div>
                        <SurchargeCommodityFormComponent :initialSurchargecommodity="surchargeitem.commodities" :surchargeTypes="surchargeitem.surcharge_types" :allCommodities="commodities" :parentIndex="index" 
                                                         :currencyAndUnitOptions="{currencies: currencies, calculation_units: calcunits}" :isDisabled="isDisabled"></SurchargeCommodityFormComponent>

                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center">
            <button type="button" class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addSurchargeItem()" :disabled="isDisabled">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('sales.surcharge_items', 1)])}}
            </button>
        </div>
        
    </div>
    <Files :files="surcharge.file_info" @addFile="addFiles" parentItemName="surcharge" />
    </div>

</template>

<script>
    
    import relationService from "@/services/RelationService"
    import surchargetypeService from "@/services/SurchargetypeService"
    import SurchargeCommodityFormComponent from '@/components/contractcommodity/SurchargeCommodityFormComponent'
    import commodityService from "@/services/CommodityService"
    import portService from '@/services/PortService'
    import moment from 'moment'
    import Files from '@/components/formpopup/Files'
    import fileService from "@/services/FileService"
    import currencyService from "@/services/CurrencyService"
    import calculationunitService from "@/services/CalculationunitService"
    import store from '@/store/user'

    export default {
        name: 'SurchargeForm',
        props: ['itemData'],
        components: {
            SurchargeCommodityFormComponent,
            Files
        },
        data() {
            return {
                region_type_id:'',
                startDate: '',
                endDate: '',
                surcharge: { carriers: [] },
                customers: [],
                carriers: [],
                ports: [],
                surchargeTypes: [],
                currencies: [],
                calcunits: [],
                submitted: false,
                loading: false,
                invalid: {},
                contractType:  this.$route.name.toLowerCase().replace('s',''),
                measurementConditionalTags: ['cm', 'cm', 'cm', 'kg', 'm²', 'm³', 'w/m'],
                selectedItemIndex: 0,
                dropzoneTarget: null,
                isEditing: false,
                isCopying: false,
                isDisabled: false,
                commodities: [],
                dataItemsLoaded: 0,
                dataItemsToLoad: 7,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.surcharge = val    
            }
            
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let message = "";
                let valid = true;
                let required = document.querySelectorAll('#formModal .dp__input_wrap input.is-invalid');

                //Check tab 1
                if(required.length > 0){
                    valid = false;
                    message = "You have filled in an invalid date";
                }
                if(!valid) {
                    alert(message);
                    this.setTab(1, true);
                    return valid;
                }

                //Check tab 2
                
                //Check selects and number fields
                required = document.querySelectorAll('.tabbable-hide, .stype-column select, .tabbable-hide');
                for(let field of required) {
                    if(!field.value || field.value === this.$t('form.select')){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                //Check commodities
                let noCommodities = false;
                for(let item of this.surcharge.items){
                    for(let commodity of item.commodities){
                        if(commodity.commodities.length == 0){
                            noCommodities = true;
                            break;
                        }
                    }
                }
                required = document.querySelectorAll('form .commodity-name');
                for(let field of required) {
                    if(!field.value){
                        valid = false;
                        field.classList.add("is-invalid");
                        noCommodities = true;
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                if(noCommodities){
                    valid = false;
                    message = "There are commodities that are yet to be selected.";
                }
                //Check surcharge types
                required = document.querySelectorAll('.stype-column');
                for(let column of required) {
                    if(column.childElementCount == 0){
                        valid = false;
                        message = "There are surcharge types that are yet to be selected.";
                    }
                }
                //Check ports
                required = document.querySelectorAll('#items .simple-typeahead+div');
                for(let column of required) {
                    if(column.childElementCount == 0){
                        valid = false;
                        message = "There are ports that are yet to be selected.";
                    }
                }
                
                //Check if there is at least one item
                if(this.surcharge.items.length == 0){
                    valid = false;
                    message = "There are no surcharge items.";
                }

                if(!valid) alert(message);
                return valid;
            },
            validateDate(){
                let sdate = new Date(this.surcharge.start_date).getTime();
                let edate = new Date(this.surcharge.end_date).getTime();
                let sdateSpan = document.getElementById("edatespan");
                let isValid = true;
                if(sdate > edate){
                    isValid = false;
                    sdateSpan.innerHTML = this.$t('errors.date_inverse');
                }
                if(isValid){
                    document.querySelector(".start-date .dp__input_wrap").classList.remove('is-invalid');
                    document.querySelector(".end-date .dp__input_wrap").classList.remove('is-invalid');
                    sdateSpan.parentElement.classList.remove('d-block');
                }
                else{
                    document.querySelector(".start-date .dp__input_wrap").classList.add('is-invalid');
                    document.querySelector(".end-date .dp__input_wrap").classList.add('is-invalid');
                    sdateSpan.parentElement.classList.add('d-block');
                }
            },
            getData() {
                return this.surcharge;
            },
            setData(data) {
                if(data){
                    this.surcharge = data;
                    this.isDisabled = !store.getters.getCompanyHq && data.office_id != store.getters.getCompanyId;
                    this.loadData();
                    return;
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad) return;
                this.setCustomerName(this.surcharge.customer_id);
                if(this.surcharge.file_info === undefined){
                    this.surcharge.file_info = [];
                }
                this.surcharge.carriers.forEach(carrier => {
                    carrier.name = carrier.relation.name;
                })
                if(this.isCopying) {
                    this.surcharge.id = null;
                }
                this.surcharge.items.forEach(item => {
                    if(this.isCopying) {
                        this.deleteIds(item);
                    }
                    item.commodities.forEach(comm => {
                        comm.surcharge_types.forEach(type => {
                            type.sales_same_as_buying = (type.buying_rate == type.sales_rate && type.buying_rate_unit_id == type.sales_rate_unit_id &&
                                                         type.buying_rate_currency_id == type.sales_rate_currency_id) ? 1 : 0;
                            type.tag = type.surcharge_type.measurement_conditional_id ? this.measurementConditionalTags[type.surcharge_type.measurement_conditional_id - 1] : '-';
                        });
                    });
                });
                
                this.setTab(1, true);
                this.checkStepOne();
                this.dataItemsLoaded = 0;
                this.isCopying = false;
            },
            reset(isEditing = false, isCopy = false) {
                this.isDisabled = false;
                this.isEditing = isEditing || isCopy;
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.isCopying = isCopy;
                }
                if(!isEditing && !isCopy && this.dataItemsLoaded < this.dataItemsToLoad){
                    this.loadData();
                    return;
                }

                let cName = document.getElementById("customer_name");
                if (cName != null) cName.value = "";
                this.selectedItemIndex = 0;
                this.surcharge = {
                    'start_date': moment().quarter(moment().quarter() + 1).startOf('quarter').format('YYYY-MM-DD'),
                    'end_date': moment().quarter(moment().quarter() + 1).endOf('quarter').format('YYYY-MM-DD'),
                    'carriers': [],
                    'file_info': [],
                    'items': [{
                        'surcharge_id': null,
                        'loading_ports': [],
                        'discharge_ports': [],
                        'surcharge_types': [],
                        'commodities': [{
                            'id': null,
                            'commodities': this.hardCopy(this.commodities),
                            'surcharge_types': []
                        }]
                    }],
                }
                this.contractType =  this.$route.name.toLowerCase().replace('s','');
                if(!isCopy){
                    this.surcharge.items[0].commodities[0].surcharge_types.push({
                        surcharge_type_id: null,
                        sales_same_as_buying: 1,
                        buying_rate_unit_id: '',
                        sales_rate_unit_id: '',
                        sales_rate: '',
                        sales_rate_currency_id: window.defaultCurrency,
                        buying_rate: '',
                        buying_rate_currency_id: window.defaultCurrency,
                        sub_surcharges: [],
                        has_measurement_conditionals: false,
                        condition_from: null,
                        condition_every: null
                    });
                }
                document.querySelector(".start-date .dp__input_wrap").classList.remove('is-invalid');
                document.querySelector(".end-date .dp__input_wrap").classList.remove('is-invalid');
                document.getElementById("edatespan").parentElement.classList.remove('d-block');
                this.loading = false
                this.invalid = {}
                this.setTab(1, true);
                this.checkStepOne();
            },

            addFiles(fileInfo){
                if (this.surcharge.id) {
                    fileService.upload({"file_info": [fileInfo],"id": this.surcharge.id,"model": 'surcharge'}).then(response => {
                        this.surcharge.file_info.unshift(response.data); 
                    }).catch(error => {
                        console.log('error', error)
                    })
                } else {
                    this.surcharge.file_info.unshift(fileInfo); 
                }

            },
            loadData(){
                relationService.index(null, null, 'customer').then(response => {
                    this.customers = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                commodityService.index().then(response => {
                    this.commodities = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                relationService.index(null, null, 'carrier').then(response => {
                    this.carriers = response.data;
                    this.carriers.push({id: -1, name: 'Other'});
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                portService.index().then(response => {
                    this.ports = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                surchargetypeService.index(null, null, 'surchargetype').then(response => {
                    this.surchargeTypes = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                currencyService.index().then(response => {
                    this.currencies = response.data;
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                calculationunitService.index().then(response => {
                    this.calcunits = response.data.filter(cu => cu.id < 6 || cu.id == 8);
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
            },
            addSurchargeItem() {
                //Close open accordion items
                let openAccordionItems = document.querySelectorAll(".accordion-button:not(.collapsed)");
                openAccordionItems.forEach(i => {i.click()});

                this.surcharge.items.push({
                    'surcharge_id': null,
                    'loading_ports': [],
                    'discharge_ports': [],
                    'surcharge_types': [],
                    'commodities': [{
                        'id': null,
                        'commodities': this.hardCopy(this.commodities),
                        'surcharge_types': []
                    }],

                });

            },
            deleteSurchargeItem(index) {
                if(confirm("Are you sure you want to delete this surcharge item?")) this.surcharge.items.splice(index, 1);
            },
            copySurchargeItem(item, index){
                if(confirm("Are you sure you want to copy this surcharge item?")){
                    const newItem = this.hardCopy(item);
                    this.deleteIds(newItem);
                    this.surcharge.items.splice(index + 1, 0, newItem);
                    this.$nextTick(() => {
                        const copyFrom = document.querySelector("#heading-" + index + " button");
                        if(!copyFrom.classList.contains("collapsed")){ 
                            copyFrom.click();
                            const copy = document.querySelector("#heading-" + (index + 1) + " button");
                            copy.classList.remove("collapsed");
                            copy.parentElement.nextElementSibling.classList.add("show");
                        }
                        this.$forceUpdate();
                    });
                }
            },
            deleteIds(newItem){
                delete newItem.id;
                delete newItem.surcharge_id;
                newItem.commodities.forEach(comm => {
                    delete comm.id;
                    delete comm.surcharge_item_id;
                    comm.surcharge_types.forEach(c => {
                        delete c.id;
                        delete c.surcharge_item_commodity_id;
                        c.sub_surcharges.forEach(cst => {
                            delete cst.pivot;
                        });
                    });
                });
            },

            setTab(tabId, shouldClickTab = false){
                if(shouldClickTab){
                    document.querySelectorAll(".nav-link")[tabId - 1].click();
                }
                document.getElementById('savebutton').style.display = tabId == 2 ? 'block' : 'none';
                document.getElementById('nextbutton').style.display = tabId == 2 ? 'none' : 'block';

                if(this.surcharge.carriers.length > 0){
                    let cNames = "";
                    let totalChosen = Math.min(3, this.surcharge.carriers.length);
                    let index = 0;
                    this.surcharge.carriers.slice(0, 3).forEach(carr => {
                        cNames += this.surcharge.surcharge_id == null ? carr.name : carr.relation.name;
                        index++;
                        if(index == totalChosen) return;
                        cNames += " / ";
                    })
                    document.getElementById("detailsSpan").innerHTML = ": " + cNames + ",&ensp;" + this.formatDate(this.surcharge.start_date, "shortDate") + " - " + this.formatDate(this.surcharge.end_date, "shortDate");
                }
                else {
                    document.getElementById("detailsSpan").innerHTML = "";
                }
            },

            setCustomerName(id){
                this.customers.forEach(customer => {
                    if (id == customer.id){
                        document.getElementById("customer_name").value = customer.name;
                    }
                });
            },

            openAccordion(e){
                e.target.previousSibling.previousSibling.previousSibling.click();
            },
            collapseOthers(index){
                let i = 0;
                document.querySelectorAll(".header-main").forEach(el => {
                    if (i != index && el.getAttribute("aria-expanded") == "true") el.click();
                    i++;
                })
            },
            isOneOfEachFieldFilledIn(item) { 
                return item.loading_ports.length > 0 && item.discharge_ports.length > 0 && item.commodities.length > 0
            },
            getLoadingPortsString(item, portname){
                let pString = "";
                let pAmount = 0;
                let pExtra = 0;
                item[portname].forEach(port => {
                    if(pAmount == 0)
                        pString += port.name;
                    else if((pString.length + port.name.length) <= 20)
                        pString += (', ' + port.name);
                    else
                        pExtra += 1;
                    pAmount++;
                });
                if(pExtra > 0) pString += (" +" + pExtra);
                return pString;
            },
            getCommodityNames(index){
                if(this.surchargeTypes.length == 0) return '';
                let cString = "";
                let cAmount = 0;
                let cExtra = 0;
                let comms = document.querySelectorAll("#collapse-"+index+" .commodity-name");
                comms.forEach(el => {
                    if(!el.options[el.selectedIndex]) return;
                    let value = el.options[el.selectedIndex].text;
                    if(value == this.$t('form.select'))
                        return;
                    if(cAmount == 0)
                        cString += value;
                    else if((cString.length + value.length) <= 30)
                        cString += (', ' + value);
                    else
                        cExtra += 1;
                    cAmount++;
                });
                if(cExtra > 0) cString += (" +" + cExtra);
                return cString;
            },
            checkStepOne(){
                let canGoToStepTwo = this.surcharge.carriers.length == 0;
                document.getElementById('nextbutton').disabled = canGoToStepTwo;
                document.getElementById('item-tab').disabled = canGoToStepTwo;
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing){
                        this.setData(false);
                    }
                    else{
                        this.reset();
                    }
                }
            }
        },
        mounted() {
            this.startDate = moment().quarter(moment().quarter() + 1).startOf('quarter').format('YYYY-MM-DD'); 
            this.endDate = moment().quarter(moment().quarter() + 1).endOf('quarter').format('YYYY-MM-DD');
        }
    }
</script>

<style scoped>
    .carrier-column {margin-left: 14.5rem; width: 75%}
    .wrapper label { border-width: 1px; border-style: solid; position: relative; height: 150px; text-align: center; }
    .wrapper label span { padding-top: 45px; display: block; }
    .wrapper label div { background: no-repeat center; background-size: contain; width: 100%; height: 100%; }
    .wrapper input[type="radio"]:not(:checked) + label { border-color: #666 }
    .wrapper input[type="radio"]:checked + label, .wrapper input[type="radio"] + label:hover { border-color: #0d6efd; cursor: pointer }
    .wrapper input[type="radio"]:not(:checked) + label::before { content: '\25CB'; margin-right: 6px; color: #000; font-size: 24px; position: absolute; bottom: 0px; left: 50%; }
    .wrapper input[type="radio"]:checked + label::before { content: '\25CF'; margin-right: 6px; color: #0d6efd; font-size: 24px;  position: absolute; bottom: 0px; left: 50%;  }
    #extraButtonStep2 {position: absolute; left: 1rem; bottom: 1rem}
    .accordion-item {
        margin-bottom: 1rem;
        border: 2px solid #0d6efd;
        border-radius: 6px
    }
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + *, .accordion-button:not(.collapsed) + * + *, .accordion-button:not(.collapsed) + * + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + *, .accordion-button.collapsed + * + * , .acc-header-item.collapsed + * + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }

    /* Search list */
    .search-list {
        position: fixed;
        background-color: white;
        z-index: 1000;
        border: 1px solid black;
        border-radius: 4px;
        padding: 2px;
        max-height: 300px;
        overflow-y: scroll
    }
    .search-list > div:hover {
        background-color: #adf;
        cursor: pointer;
    }
    .search-list > .hover {
        background-color: #cef
    }
</style>