<template>
<div class="row mt-1"  v-for="(surchargecommodity,index) in thissurchargecommodity" :key="index">
    <div class="mb-5" v-show="index > 0"></div>
    <hr>
    <div class="col-sm-3">
        <div class="d-flex justify-content-between">
            <div class="required-label">{{$tc('navigation.commodities', 2)}}</div>
        </div>
        <FormItem id="commodity_" type="select-multiple-fields" v-model="surchargecommodity.commodities" :options="allCommodities" :content="thissurchargecommodity" :disabled="isDisabled" />
    </div>
    <div class="col-9 ps-0 surcharge-subitem">
        <div class="trashcan-icon" >
            <button type="button" class="btn btn-sm text-danger float-right" title="Click to delete commodity" @click="deleteSurchargeCommodity(index, true)" :disabled="isDisabled">
                <i class="far fa-trash-alt"></i>
            </button>
        </div>
        <div class="row mb-2">
            <div class="col-3"></div>
            <div class="col-4 required-label">{{$tc('sales.buying_rates', 1)}}</div>
            <div class="col-4 required-label" style="padding-left: 38px">{{$tc('sales.sales_rates', 1)}}</div>
        </div>
            <div class="stype-column">
            <template v-for="(surchargeType, stIndex) in surchargecommodity.surcharge_types" :key="stIndex">
            <div class="row mb-2">
                <div class="col-3 px-0">
                    <FormItem :id="'cosl-'+index+'-'+stIndex" type="select" v-model="surchargeType.surcharge_type_id" :options="$parent.surchargeTypes" nameField="code" 
                              @onchange="setSurchargeType(surchargeType)" :required="true" :disabled="isDisabled" />
                </div>
                <div class="col-4">
                    <FormItem :id="'purchase_'+parentIndex+'_'+index+'_'+stIndex" type="currency-and-unit" v-model="surchargecommodity.surcharge_types[stIndex]" :options="currencyAndUnitOptions" 
                            :required="true" inputGroupType="buying_rate" sizeItem="12" @onchange="validate($event); setSame(surchargeType)" :calcUnitFields="calcUnitFields" :disabled="isDisabled" />
                </div>
                <div class="col-auto p-0" style="width: 27px">
                    <button type="button" class="btn btn-sm text-danger px-1" title="Click to delete surcharge" @click="deleteSurchargeType(surchargecommodity, stIndex)" :disabled="isDisabled">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
                <div class="col-4">
                    <FormItem v-if="surchargeType.sales_same_as_buying" :id="'cb_'+stIndex+'-'+index" :label="$t('sales.same_as_buying_rate')" type="checkbox" v-model="surchargeType.sales_same_as_buying"
                            @onclick="setDiff('sales_'+parentIndex+'_'+index+'_'+stIndex, surchargeType)" :disabled="isDisabled" />
                    <FormItem v-else :id="'sales_'+parentIndex+'_'+index+'_'+stIndex" type="currency-and-unit" v-model="surchargecommodity.surcharge_types[stIndex]" :options="currencyAndUnitOptions" 
                            :required="true" inputGroupType="sales_rate" @onchange="validate" @onblur="resetSameOnBuying($event, surchargeType)" :calcUnitFields="calcUnitFields" :disabled="isDisabled" />
                </div>
                <a v-show="surchargeType.sales_rate_unit_id == 5" class="col-auto btn btn-sm text-success me-1" @click="setPercentageBlurb(e, surchargeType)"><i class="far fa-info-circle"></i></a>
            </div>
            <div class="row" v-if="surchargeType.surcharge_type ? surchargeType.surcharge_type.has_measurement_conditionals : surchargeType.has_measurement_conditionals">
                <div class="col-2 col-form-label-sm ps-2">{{$t('masterdata.conditionials')}}</div>
                <div class="col-2 ps-2" style="width: 19.3%">
                    <FormItem :id="'con1_'+parentIndex+'_'+index+'_'+stIndex" type="number-with-tags" v-model="surchargeType.condition_from" :required="true" :label="$t('overview.from')" :endTag="surchargeType.tag" :disabled="isDisabled" />
                </div>
                <div class="col-2" style="width: 21.7%">
                    <FormItem :id="'con2_'+parentIndex+'_'+index+'_'+stIndex" type="number-with-tags" v-model="surchargeType.condition_every" :required="true" :label="$t('overview.every')" :endTag="surchargeType.tag" :disabled="isDisabled" />
                </div>
            </div>
            </template>
        </div>
        <div class="row mt-2">
            <button type="button" class="btn btn-sm btn-primary w-auto" title="Click to add commodity" @click="addSurchargeType(surchargecommodity)" :disabled="isDisabled">
                <i class="fa fa-plus"></i>
            </button>
        </div>
    </div>
</div>

<div class="row mt-4 justify-content-center">
    <button type="button" class="btn btn-sm btn-primary w-25 mb-4" title="Click to add commodity" @click="addSurchargeCommodity()" :disabled="isDisabled">
        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.commodities', 2)])}}
    </button>
</div>

<div v-if="selectedSurchargeType">
    <div class="modal" :class="{'d-block': popupVisible}" tabindex="-1" style="background-color: rgba(1,1,1,0.4)" data-blurb="" @click="closeInnerModel">
        <div class="modal-dialog modal-dialog-scrollable modal-md">
            <div class="modal-content">
                <div class="modal-body">
                    <p>{{$t('sales.surcharge_blurb')}}</p>
                    <p class="text-muted">{{$t('masterdata.base_freight')}}</p>
                    <FormItem id="pcfg" type="select-multiple-fields" v-model="selectedSurchargeType.sub_surcharges" :options="$parent.surchargeTypes" 
                              :content="[]" nameField="code" :extraOption="selectedSurchargeType.sub_surcharges" :disabled="isDisabled" />
                    <br />
                </div>
                <div class="pb-2 pe-3 text-end"><a class="btn btn-success w-auto" title="Close" @click="closeInnerModel">{{$t('form.save')}}</a></div>
            </div>
        </div>
    </div>
</div>
                        
</template>

<script>
    export default {
        props: ['initialSurchargecommodity','surchargeTypes','allCommodities','parentIndex', 'currencyAndUnitOptions', 'isDisabled'],
        name: 'SurchargecommodityListComponent',
        computed: {
            thissurchargecommodity: {
                get() {
                    return this.initialSurchargecommodity
                }
            }
        },
        watch: {
            initialSurchargecommodity: {
                deep: true,
                handler(initialSurchargecommodity) {
                    this.$emit('surchargecommoditychange', initialSurchargecommodity)
                }
            }
        },
        data() {
            return {
                surcharges:[],
                surcharge_item_commodity_carriers: [],
                submitted: false,
                loading: false,
                invalid: {},
                selectedSurchargeType: null,
                popupVisible: false,
                defaultCurrency: null,
                calcUnitFields: ['currency_id', 'unit_id']
            }
        },
        methods: {

            validate(e){
                let el = e.target;
                if(el.value) {
                    if(el.nodeName != "SELECT")
                        el.classList.add('is-valid');
                    el.classList.remove('is-invalid');
                }
                else el.classList.remove('is-valid');
            },
            resetSameOnBuying(e, surchargeType = null){
                let el = e.target;
                //To make checkmark appear again
                if(!el.value && surchargeType != null){
                    surchargeType.sales_same_as_buying = 1;
                    surchargeType.sales_rate = surchargeType.buying_rate;
                }
            },
            addSurchargeCommodity() {
                let surcharges = [];
                this.surchargeTypes.forEach(st => {
                    surcharges.push({
                        surcharge_type_id: st.id,
                        sales_same_as_buying: 1,
                        buying_rate_unit_id: '',
                        sales_rate_unit_id: '',
                        sales_rate: '',
                        sales_rate_currency_id: window.defaultCurrency,
                        buying_rate: '',
                        buying_rate_currency_id: window.defaultCurrency,
                        sub_surcharges: [],
                        has_measurement_conditionals: false,
                        condition_from: null,
                        condition_every: null
                    });
                });
                this.thissurchargecommodity.push({
                    commodities: [{'id': ''}],
                    surcharge_types : surcharges
                });
            },
            addCommodity(st){
                st.commodities.push({'id': ''});
            },
            deleteCommodity(st, index){
                st.commodities.splice(index, 1);
            },
            deleteSurchargeCommodity(index, askForConfirm = false) {
                if(askForConfirm && confirm("Are you sure you want to delete this commodity?")) this.thissurchargecommodity.splice(index, 1);
                if (!askForConfirm) this.thissurchargecommodity.splice(index, 1);
            },
            deleteSurchargeType(item, index){
                item.surcharge_types.splice(index, 1);
            },
            addSurchargeType(item){
                item.surcharge_types.push({
                    surcharge_type_id: null,
                    sales_same_as_buying: 1,
                    buying_rate_unit_id: '',
                    sales_rate_unit_id: '',
                    sales_rate: '',
                    sales_rate_currency_id: window.defaultCurrency,
                    buying_rate: '',
                    buying_rate_currency_id: window.defaultCurrency,
                    has_measurement_conditionals: false,
                    condition_from: null,
                    condition_every: null,
                    sub_surcharges: []
                });
            },
            setSurchargeType(item){
                let stype = this.$parent.surchargeTypes.find(st => st.id == item.surcharge_type_id);
                if(!stype) return;
                item.has_measurement_conditionals = stype.has_measurement_conditionals;
                item.tag = stype.measurement_conditional_id ? this.$parent.measurementConditionalTags[stype.measurement_conditional_id - 1] : '-';
            },
            getDisplayName(index){
                let cname = document.querySelectorAll('.carrier-column span')[index].innerHTML;
                return (cname.length > 16) ? (cname.slice(0, 15) + '...') : cname;
            },
            setSame(surchargeItem){
                if(surchargeItem.sales_same_as_buying){
                    surchargeItem.sales_rate = surchargeItem.buying_rate;
                    surchargeItem.sales_rate_currency_id = surchargeItem.buying_rate_currency_id;
                    surchargeItem.sales_rate_unit_id = surchargeItem.buying_rate_unit_id;
                }
            },
            setDiff(id, stype){
                stype.sales_rate = null;
                this.$nextTick(() =>
                    window.setTimeout(() => document.getElementById(id).focus(), 10)
                );
            },
            setPercentageBlurb(e, item){
                this.popupVisible = true;
                this.selectedSurchargeType = item;
            },
            checkIfAlreadyChosen(elId, optionValue, commodity){
                let comms = [];
                const select = document.getElementById(elId);
                if(select != null && select.value === optionValue) return true;
                this.thissurchargecommodity.forEach(tsc => {
                    tsc.commodities.forEach(tscc => {
                        comms.push(tscc.id);
                    })
                })
                return comms.includes(commodity.id);
            },
            closeInnerModel(e){
                if(e.target.classList.contains('modal') || e.target.classList.contains('btn-success')) this.popupVisible = false;
            },

            forceUpdate(e){
                this.validate(e);
                this.addSurchargeCommodity(true);
                this.deleteSurchargeCommodity(this.thissurchargecommodity.length - 1);
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            }
            
        }
    }
</script>

<style scoped>
.trashcan-icon {
    position: absolute;
    right: 40px
}
.surcharge-subitem .col-3{
    width: calc(25.5% - 27px)
}
.surcharge-subitem .col-4{
    width: 35%
}
</style>