<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'start_date'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :showCopy="true"
        :deleteText="setDeleteText"
        :selectFields=selectFields
        :filterOptions="filterOptions"
    >
        <template #columns>
            <Column :header="$t('navigation.surcharge_types')">
                <template #body="slotProps">
                    {{getTypeNames(slotProps.data.items)}}
                </template>
            </Column>
            <Column :header="$tc('navigation.carriers', 2)">
                <template #body="slotProps">
                    {{getCarrierNames(slotProps.data.carriers)}}
                </template>
            </Column>
            <Column :header="$t('navigation.regions')">
                <template #body="slotProps">
                    {{calcRegion(slotProps.data)}}
                </template>
            </Column>
            <Column field="start_date" :header="$t('overview.start_date')" style="width: 120px" :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.start_date, 'shortDate')}}
                </template>
            </Column>
            <Column field="end_date" :header="$t('overview.end_date')" style="width: 120px" :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.end_date, 'shortDate')}}
                </template>
            </Column>
        </template>

        <template #form>
            <SurchargeForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import SurchargeForm from '@/components/surcharge/SurchargeForm.vue';
import surchargeService from '@/services/SurchargeService';
import surchargetypeService from '@/services/SurchargetypeService';
import carrierService from '@/services/CarrierService';

export default {
    components: {
        Overview,
        Column,
        SurchargeForm
    },
    data() {
        return {
            item: {},
            regions: [],
            itemService: surchargeService,
            itemFormRef: null,
            filterOptions: [
                {
                    field: 'carriers.relation_id',
                    service: carrierService,
                    name: this.$t('navigation.carriers'),
                    idField: 'relation_id',
                    multiple: true
                },
                {
                    field: 'items.commodities.surchargeTypes.surcharge_type_id',
                    service: surchargetypeService,
                    checkboxes: [],
                    name: this.$t('navigation.surcharge_types'),
                    nameField: 'code'
                },
            ],
            selectFields: [
                "id",
                "start_date",
                "end_date",
                {
                    "carriers": [
                        "carriers.id",
                        "relation_id",
                        {
                            "relation": [
                                "relations.id",
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "items": [
                        "id",
                        "surcharge_id",
                        {
                            "loadingPorts": [
                                "id",
                                "name",
                                {
                                    "shortSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                },
                                {
                                    "deepSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                }
                            ]
                        },
                        {
                            "dischargePorts": [
                                "id",
                                "name",
                                {
                                    "shortSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                },
                                {
                                    "deepSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            setDeleteText: (item) => this.getCarrierNames(item.carriers, true)
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    break;
                }
                if(tab.className == "nav-link active") next = true;
                
            }
        },
        getCarrierNames(data, forDeletePopup = false){
            let result = "";
            if(!data){
                return result;
            }
            data.forEach(el => { result += (el.relation ? (el.relation.name + ', ') : '') });
            result = result.slice(0, -2);
            if(result.length > 26 && !forDeletePopup){
                result = result.slice(0, 25) + '...';
            }
            if(forDeletePopup){
                result = "the surcharge for " + result;
            }
            return result;
        },
        calcRegion(data){
            let allRegionNames = [];
            let allRegionIds = [];
            function lookForPortRegions(port){
                try{
                    const key = port.short_sea_region_id !== null ? 'short_sea_region' : 'deep_sea_region';
                    const id = port[key].id;
                    const name = port[key].name;
                    if(!allRegionIds.includes(id) ) {
                        allRegionNames.push(name);
                        allRegionIds.push(id);
                    }
                }
                catch(e){
                    console.log(e);
                }
            }
            data.items.forEach(item => {
                item.loading_ports.forEach(port => lookForPortRegions(port));
                item.discharge_ports.forEach(port => lookForPortRegions(port));
            });
            return allRegionNames.join(' - ');
        },
        getTypeNames(data){
            let result = "";
            let resultArray = [];
            data.forEach(el => {
                el.commodities.forEach(comm => {
                    comm.surcharge_types.forEach(type => {
                        if(!resultArray.includes(type.surcharge_type.code))
                            resultArray.push(type.surcharge_type.code);
                    });
                });
            });
            result = resultArray.join(', ');
            if(result.length > 26){
                result = result.slice(0, 25) + '...';
            }
            return result;
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>